import React from "react";

function Loader() {
  return (
    <div class="loaders">
      <h1 className="loader"></h1>
    </div>
  );
}

export default Loader;
