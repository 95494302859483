/*
 * (c) Classify
 */

import ClassifyConfig from "../model/ClassifyConfig";
import ClassifyListing from "../model/ClassifyListing";

//

/**
 * Loads and configures CloudKit
 */
class RetrieveListingCommand {
  /**
   * The Promise resolve function used to resolve the command asynchronously
   */
  #resolveHandler;

  /**
   * The ClassifyListing object that is built from the server responses
   */
  #listing;

  //

  /**
   * Constructs the Command
   */
  constructor(recordName, mock) {
    this.recordName = recordName;
    this.mock = mock;
  }

  /**
   * Executes the command
   */
  execute() {
    return new Promise((resolve, error) => {
      this.#resolveHandler = resolve;
      if (this.mock) {
        this.#resolveHandler({ listing: ClassifyListing.createMockListing() });
      } else {
        this.#fetchRecord();
      }
    });
  }

  // Private

  /**
   * Fetches a record from CloudKit
   */
  #fetchRecord() {
    const container = window.CloudKit.getDefaultContainer();
    const database = container.publicCloudDatabase;
    const options = {
      desiredKeys: ["title", "content", "price"],
    };

    database
      .fetchRecords(this.recordName, options)
      .then((response) => {
        if (response.hasErrors) {
          this.#resolveHandler({ error: response.errors[0] });
        } else {
          const record = response.records[0];
          this.#listing = new ClassifyListing();
          this.#listing.id = this.recordName;
          this.#listing.title = this.#getServerResponseFieldValue(
            record,
            "title"
          );
          this.#listing.content = this.#getServerResponseFieldValue(
            record,
            "content"
          );
          this.#listing.price = this.#getServerResponseFieldValue(
            record,
            "price"
          );
          this.#fetchListingAttachmentMeta();
        }
      })
      .catch((error) => {
        this.#resolveHandler({ error: error });
      });
  }

  /**
   * @return The value of a field contained in a server response object
   * or an empty string if it is null
   */
  #getServerResponseFieldValue(response, fieldName) {
    const value = response?.fields?.[fieldName].value;
    return value != null ? value : "";
  }

  /**
   * Retrieves the listing's attachment meta data (meta data for the priority 1 image)
   */
  #fetchListingAttachmentMeta() {
    const container = window.CloudKit.getDefaultContainer();
    const database = container.publicCloudDatabase;
    const query = {
      recordType: "ListingAttachmentMeta",
      filterBy: [
        {
          fieldName: "listing",
          comparator: "EQUALS",
          fieldValue: {
            value: {
              recordName: this.recordName,
              recordType: "Listing",
            },
          },
        },
        {
          fieldName: "priority",
          comparator: "EQUALS",
          fieldValue: {
            value: 1,
          },
        },
      ],
    };

    const options = {
      desiredKeys: ["attachmentMetadata"],
    };

    database
      .performQuery(query, options)
      .then((response) => {
        if (response.hasErrors) {
          // this.#resolveHandler({ error: response.errors[0] });
          // ignore the error, the listing has no images
          this.#resolveHandler({ listing: this.#listing });
        } else {
          if (response.records.length > 0) {
            this.#fetchAttachmentMeta(
              response.records[0].fields.attachmentMetadata.value.recordName
            );
          } else {
            // the listing has no images
            this.#resolveHandler({ listing: this.#listing });
          }
        }
      })
      .catch((error) => {
        // ignore the error, show the listing without images
        this.#resolveHandler({ listing: this.#listing });
      });
  }

  /**
   * Retrieves an attachment's meta data
   */
  #fetchAttachmentMeta(attachmentRecordName) {
    const container = window.CloudKit.getDefaultContainer();
    const database = container.publicCloudDatabase;
    const query = {
      recordType: "AttachmentMeta",
      filterBy: [
        {
          fieldName: "___recordID",
          comparator: "EQUALS",
          fieldValue: {
            value: {
              recordName: attachmentRecordName,
              recordType: "AttachmentMeta",
            },
          },
        },
      ],
    };

    database
      .performQuery(query)
      .then((response) => {
        if (response.hasErrors) {
          // this.#resolveHandler({ error: response.errors[0] });
          // ignore the error, the listing has no images
          this.#resolveHandler({ listing: this.#listing });
        } else {
          if (response.records.length > 0) {
            this.#listing.image = `${ClassifyConfig.classifyImageBaseUrl}/${this.recordName}/${response.records[0].recordName}.jpg`;
          }

          this.#resolveHandler({ listing: this.#listing });
        }
      })
      .catch((error) => {
        // ignore the error, show the listing without images
        this.#resolveHandler({ listing: this.#listing });
      });
  }
}

export default RetrieveListingCommand;
