/*
 * (c) Classify
 */
import React, { Component } from 'react';
import NavigateToClassifyApp from '../commands/NavigateToClassifyApp';
//
/**
 * Displays a listing
 */
class ListingDetailComponent extends Component {
	/**
	 * Renders the component
	 */
	render() {
		const listing = this.props.listing;
		const markup = <article className='listing-detail'>
			<img src={listing.getImage()} style={{ width: '100%' }} onClick={this.#handleListingClick.bind(this)}/>
			<div className='details'>
				<h1 className='title'>{listing.getTitle()}</h1>
				{ listing.getPrice() > 0 && <span className='price'>{listing.getFormattedPrice()}</span> }
				<p className='description'>{listing.getContent()}</p>
			</div>
		</article>;
		return markup;
	}
	//
	/**
	 * Handles clicks on the listing detail, links to the classify app
	 */
	#handleListingClick(e){
		const navigateToApp = new NavigateToClassifyApp(this.props.listing.getId());
		navigateToApp.execute();
	}
}
//
export default ListingDetailComponent;
