/*
 * (c) Classify
 */

//

/**
 * Configuration object for the Classify Web App
 */
const ClassifyConfig = {

	// 

	/**
	 * Specifies the URL path for a listing
	 */
	listingUrlPath: 'listing',

	/**
	 * Specifies the URL scheme for the classify app
	 */
	classifyUrlScheme: 'classify://',

	/**
	 * The URL to Classify in the Apple app store
	 */
	classifyAppleAppStoreUrl: 'http://itunes.apple.com/lb/app/classify',

	/**
	 * Image base URL
	 */
	classifyImageBaseUrl: 'https://classify161856-production.s3-us-west-2.amazonaws.com/public',

	// CloudKit

	/**
	 * Defines the URL for the CloudKit client side JS
	 */
	cloudKitClientLibraryUrl: 'https://cdn.apple-cloudkit.com/ck/2/cloudkit.js',

	/**
	 * Defines the CloudKit configuration object
	 */
	cloudKitConfig: {
		containers: [{
			containerIdentifier: 'iCloud.v18.classify.app',
			apiTokenAuth: {
				apiToken: '59b534d2f249ac6aa625c93bc4f2135a26d24a79983ed29fddc72b1410e731dc',
				persist: false
			},
			environment: 'production'
		}]
	}

};

//

export default ClassifyConfig;