/*
 * (c) Classify
 */
import React, { Component } from "react";
import InitializeCloudKitCommand from "./commands/InitializeCloudKitCommand";
import RetrieveListingCommand from "./commands/RetrieveListingCommand";
import ListingDetailComponent from "./components/ListingDetailComponent";
import ClassifyConfig from "./model/ClassifyConfig";
import { Helmet } from "react-helmet";
import HomepageComponent from "./components/HomepageComponent";
import appiconlight from "../assets/images/AppIcon-1024px-light-100.png";
import appicondark from "../assets/images/AppIcon-1024px-dark-100.png";
import Loader from "./components/loader";
//
/**
 * The main React component for Classify
 */
class ClassifyWebApp extends Component {
  /**
   * Constructs the component
   */
  constructor() {
    super();
    this.state = {
      listing: null,
      error: false,
      isDark: window.matchMedia("(prefers-color-scheme: dark)").matches,
    };
    this.#initialize();
    this.handleColorSchemeChange = this.handleColorSchemeChange.bind(this);
  }
  componentDidMount() {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    darkModeMediaQuery.addListener(this.handleColorSchemeChange);
  }
  componentWillUnmount() {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    darkModeMediaQuery.removeListener(this.handleColorSchemeChange);
  }
  handleColorSchemeChange(event) {
    this.setState({ isDark: event.matches });
  }
  // Markup and Rendering
  /**
   * Renders the component
   */
  render() {
    const content =
      this.state.listing == null && !this.state.error
        ? ""
        : this.getListingMarkup();
    /* app theme */
    const lightTheme = {
      backgroundColor: "white",
      color: "black",
    };
    const darkTheme = {
      backgroundColor: "#191919",
      color: "white",
    };

    const markup = (
      <>
        <Helmet>
          <meta
            name="keywords"
            content="Online Marketplace, Community Marketplace, Buy and Sell Online, Video Listings, AR Listings, Local Classifieds, Social Commerce, Interactive Listings, User-Generated Content, Trade and Barter Platform, Peer-to-Peer Marketplace, Classified Ads, Local Trading, Social Selling, Video Commerce, AR Commerce, Virtual Try-On, Community Commerce, Secondhand Marketplace, Online Trading, Local Buy and Sell, Social Marketplace, Video Classifieds, AR Classifieds"
          />
          <title>Classify.app 🚀👨‍🚀✌🏻 | Classifieds for everyone.</title>
        </Helmet>
        <div
          className="classify"
          style={this.state.isDark ? darkTheme : lightTheme}
        >
          {typeof window !== "undefined" &&
          window.location.pathname === "/" ? null : (
            <header>
              <a
                href="https://apps.apple.com/us/app/classify-app/id1458197926?itscg=30200&amp;itsct=apps_box_appicon"
                style={{ width: "70px" }}
              >
                {/* <img
                  src={this.state.isDark ? appicondark : appiconlight}
                  alt="Classify App"
                  style={{ width: "70px", borderRadius: "15px" }}
                /> */}
                <img
                  src="https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/93/db/43/93db43a7-14b6-5ecc-c748-ce90795647ff/AppIcon-0-0-1x_U007emarketing-0-7-0-85-220.png/540x540bb.jpg"
                  alt="Classify App"
                  style={{ width: "70px", borderRadius: "15px" }}
                ></img>
              </a>
            </header>
          )}
          <hr />
          <main>
            {typeof window !== "undefined" &&
            window.location.pathname === "/" ? (
              <HomepageComponent systemMode={this.state.isDark} />
            ) : content ? (
              content
            ) : (
            <Loader/>
            )}
          </main>
          <hr />
          <footer>
            <span>
              <a href="https://neutrinos.app/index.html">
                &copy; Neutrinos Platforms
              </a>
            </span>
            <span className="links">
              <a href="https://neutrinos.app/contact.html">Contact</a>
              <a href="https://neutrinos.app/privacy.html">Privacy</a>
            </span>
          </footer>
        </div>
      </>
    );
    return markup;
  }
  /**
   * @return the markup for the listing or an error if no listing can be found
   */
  getListingMarkup() {
    const markup = !this.state.error ? (
      <ListingDetailComponent listing={this.state.listing} />
    ) : (
      this.getErrorMarkup()
    );
    return markup;
  }
  /**
   *
   */
  getErrorMarkup() {
    return <div>We're sorry but that listing cannot be found</div>;
  }
  //
  /**
   * Initializes the app
   */
  async #initialize() {
    // first, determine if we are looking at and have a valid listing ID
    const paths = window.location.pathname.split("/");
    if (paths.length > 2 && paths[1] === ClassifyConfig.listingUrlPath) {
      const listingId = paths[2];
      // if we do have a listing ID, retreive it
      if (listingId.split(" ").join("") != "") {
        await this.#initCloudKit();
        await this.#retrieveListing(listingId);
        // otherwise display an error
      } else {
        this.state.error = true;
      }
    }
  }
  /**
   * Initializes CloudKit
   */
  async #initCloudKit() {
    const initCloudKit = new InitializeCloudKitCommand();
    await initCloudKit.execute();
  }
  /**
   * Initializes CloudKit
   */
  async #retrieveListing(listingId) {
    const retrieveListing = new RetrieveListingCommand(listingId, false);
    const response = await retrieveListing.execute();
    if (response.listing != null) {
      this.setState({ listing: response.listing });
    } else {
      this.setState({ error: true });
    }
  }
}
export default ClassifyWebApp;
