import React, { Component } from "react";
import lightAppStoreIcon from "../../assets/images/white.svg";
import darkAppStoreLogo from "../../assets/images/black.svg";
import TitleAppLogo from "../../assets/images/logo.93a159a3.png";
import lqrcode from "../../assets/images/qr-code.jpg";
import dqrcode from "../../assets/images/qr-code_dark.jpg";

export default class HomepageComponent extends Component {
  render() {
    return (
      <>
        <div className="logoImageSec">
          <a href="https://classify.app/ClassifyProX.mov">
            <img className="logo_icon" src={TitleAppLogo} alt="Logo" />
          </a>
        </div>
        <div className="contentSec">
          <div className="secureSec">
            <h1>Secure & Verified</h1>
            <p>
              Classify.app takes your security seriously. With Sign In With
              Apple's Real ID, rest assured that you'll know when a user is
              verified, fostering a safe and trustworthy marketplace community.
            </p>
          </div>
          <div className="privactSec">
            <h1>🔐</h1>
            <h1 className="sec_title">Security. Privacy. Trust.</h1>
            <h4>Classifieds for everyone.</h4>
            <p>
              A marketplace & community enabling
              users to securely post & explore classified listings.
            </p>
          </div>
          <div className="apple_icons_sec">
            {this.props?.systemMode ? (
              <a href="https://apps.apple.com/us/app/classify-buy-sell-connect/id1458197926?itsct=apps_box_link&itscg=30200">
                <img src={darkAppStoreLogo} alt="Apple Store Logo" />
              </a>
            ) : (
              <a href="https://apps.apple.com/us/app/classify-buy-sell-connect/id1458197926?itsct=apps_box_link&itscg=30200">
                <img src={lightAppStoreIcon} alt="Apple Store Logo" />
              </a>
            )}
          </div>
          <div className="QRcodeSec">
            <div>
              {this.props?.systemMode ? (
                <img src={dqrcode} alt="Logo" />
              ) : (
                <img src={lqrcode} alt="Logo" />
              )}
            </div>
          </div>
          <div className="bottomparagraph">
            Apple, the Apple logo, iPhone, and iPad are trademarks of Apple
            Inc., registered in the U.S. and other countries and regions. App
            Store is a service mark of Apple Inc. Copyright © 2023 Neutrino Labs
            Inc.
          </div>
        </div>
      </>
    );
  }
}
