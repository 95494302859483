/*
 * (c) Classify
 */

//

/**
 * Listing model object
 */
class ClassifyListing {

	id;
	title;
	content;
	price;
	image;

	//

	/**
	 * Defines the structure of the ClassifyListing class. Used with ObjectUtil.CreateFromDefinition
	 */
	static createMockListing() {
		const listing = new ClassifyListing();
		listing.id = '1D8EA085-CCEC-490E-BD76-2D7BA77CC43D';
		listing.title = 'ClassifyListing Title';
		listing.content = 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.';
		listing.price = 123456;
		listing.image = '/assets/images/test-image.jpg';
		return listing;
	}

	//

	/**
	 * @return The id of the listing
	 */
	getId() {
		return this.id;
	}

	/**
	 * @return The title of the listing
	 */
	getTitle() {
		return this.title
	}

	/**
	 * @return The title of the listing
	 */
	getContent() {
		return this.content;
	}

	/**
	 * @return The price of the listing
	 */
	getPrice() {
		return this.price;
	}

	/**
	 * @return The formatted price of the listing
	 */
	getFormattedPrice() {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD'
		});
		return formatter.format(this.price);
	}

	/**
	 * @return The image URL of the listing
	 */
	getImage() {
		return this.image;
	}

};

//

export default ClassifyListing;