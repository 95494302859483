/*
 * (c) Classify
 */

import ClassifyConfig from "../model/ClassifyConfig";

//

/**
 * Loads and configures CloudKit
 */
class NavigateToClassifyApp {
	
	//

	/**
	 * Constructs the Command
	 */
	constructor(recordName) {
		this.recordName = recordName;
	}

	/**
	 * Executes the command
	 */
	execute() {
		fetch(ClassifyConfig.classifyUrlScheme)
			.then((response) => {
				if(response.ok){
					return response;
				} else {
					this.#handleUrlSchemeNotSupported();
				}
			})
			.then(( data ) => {
				this.#handleUrlSchemeSupported();
			}).catch(e => {
				this.#handleUrlSchemeNotSupported();
			}
		);
	}

	/**
	 * Handles 
	 */
	#handleUrlSchemeSupported(){
		console.info('scheme supported');
		window.location.href = `${ClassifyConfig.classifyUrlScheme}/listing/${this.recordName}`;
	}

	/**
	 * Handles cases where the classify:// url scheme is not supported -- when the app is not installed
	 */
	#handleUrlSchemeNotSupported(){
		console.info('scheme error');
		window.location.href = ClassifyConfig.classifyAppleAppStoreUrl;
	}

}

//

export default NavigateToClassifyApp;
