/*
 * (c) Classify
 */

import ClassifyConfig from '../model/ClassifyConfig';

//

/**
 * Loads and configures CloudKit
 */
class InitializeCloudKitCommand {
	
	/**
	 * The Promise resolve function used to resolve the command asynchronously
	 */
	#resolveHandler;

	//

	/**
	 * Executes the command
	 */
	execute() {
		return new Promise(resolve => {
			this.#resolveHandler = resolve;
			this.#loadCloudKit();
		});
	}

	// Private

	/**
	 * Creates a script element to load CloudKit JS
	 */
	#loadCloudKit(){
		window.addEventListener('cloudkitloaded', this.#initCloudKit.bind(this));
		const script = document.createElement('script');
		script.src = ClassifyConfig.cloudKitClientLibraryUrl;
		script.async = true;
		document.head.appendChild(script);
	}

	/**
	 * Initializes CloudKit
	 */
	#initCloudKit(){
		window.CloudKit.configure(ClassifyConfig.cloudKitConfig);
		this.#resolveHandler(true);
	}

}

//

export default InitializeCloudKitCommand;
